export const heroSlides = [
    {
        id: 1,
        description: 'Physiotherapy diagnosis and musculoskeletal approach in Cambridge',
        imagePath: './../heroCarousel/2.jpg'
    },
    {
        id: 2,
        imagePath: './../heroCarousel/3.jpg'
    },
    {
        id: 3,
        description: 'Professionalism, individualisation and commitment',
        imagePath: './../heroCarousel/4.jpg'
    },
]